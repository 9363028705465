<template>
  <b-modal
    id="modal-associate-product-event-handler"
    ref="modal-associate-product-event-handler"
    v-model="show"
    ok-only
    ok-title="Crea"
    centered
    size="md"
    :title="titleEventHandler"
    no-close-on-backdrop
    @ok.prevent="handleOk"
  >
    <!-- {{ catalogo }} -->
    <validation-observer ref="validation-observer-product-associate-modal-event-handler">
      <b-row
        no-gutters
        class="mb-2"
      >
        <ejs-grid
          :data-source="productToAssociateComputed"
          :allow-paging="true"
          :page-settings="pageSettings">
          <e-columns>
            <e-column
              :is-primary-key="true"
              field="codice"
              header-text="Codice"
              text-align="left"
              clip-mode="EllipsisWithTooltip"
              width="30" />
            <e-column
              field="nome"
              header-text="Nome"
              width="120"
              clip-mode="EllipsisWithTooltip"
            />
          </e-columns>
        </ejs-grid>
      </b-row>
      <b-row no-gutters>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="Prodotto Unificato"
            rules="required"
          >
            <b-form-group
              label="Nome Prodotto Unificato"
              label-class="font-weight-bolder"
            >
              <v-select
                v-model="prodottoUnificato"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :clearable="false"
                filterable
                :reduce="valore => ({ id: valore.id })"
                :options="allProductsOptions"
                @open="getAllProducts"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <div>Ricerca prodotti...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #loading="{ search, searching, loading }">
                  <div>Caricamento prodotti...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #searching="{ search, searching, loading }">
                  <div>Sto cercando...</div>
                </template>
                <template v-slot:option="option">
                  <div>
                    <span>{{ option.codice.concat('-', option.nome) }}</span>
                  </div>
                </template>
              </v-select>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <ejs-toast
        id="toast_type"
        ref="toastRef"
        :position="{ X: 'Right' }"
      />
    </validation-observer>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { GridPlugin, Page } from '@syncfusion/ej2-vue-grids'
import {
  BFormGroup, BRow, BCol, // BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { setProdottoNonAssociato, getProdottiUnificatii } from '@/@core/api-service/catalog/products'

Vue.use(GridPlugin)
Vue.use(ToastPlugin)

export default {
  components: {
    BRow, BCol, BFormGroup, ValidationProvider, ValidationObserver, vSelect,
  },
  props: {
    productToAssociate: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      titleEventHandler: 'Seleziona Prodotto Unificato',
      allProductsOptions: [],
      prodottoToAssociate: '',
      prodottoUnificato: '',
      required,
      locale: 'it',
      pageSettings: { pageSize: 5 },
    }
  },
  computed: {
    productToAssociateComputed() {
      return this.productToAssociate
    },
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    handleOk() {
      this.$refs['validation-observer-product-associate-modal-event-handler'].validate().then(success => {
        if (success) {
          const dataToSend = this.productToAssociateComputed.map(el => ({ idProdottoDaAssociare: el.id, idProdottoUnificato: this.prodottoUnificato.id }))
          // console.log([{ idProdottoUnificato: this.prodottoUnificato.id, idProdottoDaAssociare: this.productToAssociate.id }])
          setProdottoNonAssociato(dataToSend).then(res => {
            // console.log(res)
            if (res.data.esito === 'OK') {
              // this.prodottoUnificato
              const associatiDaRimuovere = [...res.data.prodotto]
              this.$emit('update-associate', associatiDaRimuovere)
              this.$nextTick(() => {
                this.$refs['modal-associate-product-event-handler'].toggle('#toggle-btn')
              })
            } else if (res.data.esito === 'KO') {
              this.$refs.toastRef.show({
                title: 'Errore Associazione', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
              })
            }
          }).catch(e => e)
        }
      })
    },
    openModal() {
      this.show = true
    },
    setTitle(title) {
      this.titleEventHandler = title
    },
    getAllProducts() {
      getProdottiUnificatii().then(res => {
        if (res.data.esito === 'OK') {
          this.allProductsOptions = [...res.data.prodotti]
        }
      })
    },
    /* this.$nextTick(() => {
            this.$refs['modal-associate-product-event-handler'].toggle('#toggle-btn')
          })
        }
      })
        .catch(e => e)
      // console.log('this.categories', this.categories) */
  },
  provide: {
    grid: [Page],
  },
}
</script>

<style>

</style>
