<template>
  <b-card>
    <b-row
      class=""
    >
      <b-col>
        <ejs-grid
          id="overviewgridProductNotAssociate"
          ref="overviewgridProductNotAssociate"
          :data-source="prodottiNonAssociati"
          :selection-settings="selectionSettings"
          :edit-settings="editSettings"
          :filter-settings="filterOptions"
          :allow-filtering="true"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          :allow-row-drag-and-drop="false"
          height="600"
          :allow-excel-export="true"
          :allow-pdf-export="true"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :toolbar="toolbar"
          :toolbar-click="toolbarClick"
          :action-begin="actionTableBegin"
          :action-complete="actionTableComplete"
          :context-menu-items="contextMenuItems"
          :allow-paging="true"
          :command-click="commandColumm"
          :page-settings="pageSettings"
          :data-bound="dataBound"
        >
          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              :allow-editing="false"
              width="70"
            />
            <e-column
              field="id"
              header-text="id"
              :is-primary-key="true"
              width="200"
              :visible="false"
              :allow-editing="false"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="codice"
              header-text="Codice"
              width="100"
              :filter="filter"
              :allow-editing="false"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="nome"
              header-text="Nome"
              width="200"
              :filter="filter"
              :allow-editing="false"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="150"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
              hide-at-media="(min-width:900px)"
            />
          </e-columns>
        </ejs-grid>
      </b-col>>
      <!-- {{ movimentazione }} -->
      <ejs-toast
        id="toast_type"
        ref="toastRef"
        :position="{ X: 'Right' }"
        class="z-indexAlert"
      />
    </b-row>
    <associate-products-modal-event-handler
      ref="modal-associate-product-event-handler"
      :product-to-associate="productToAssociate"
      @update-associate="removeAssociateFromTable"
    />
  </b-card>
  <!-- <b-row
    v-if="$route.name === 'details-products'"
    no-gutters
    class="m-1"
  >
    <b-col cols="12">
      <p>
        {{ getProduct.nome }}
      </p>
      <p>
        {{ getProduct.codice }}
      </p>
    </b-col>
  </b-row> -->
</template>

<script>
import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, Toolbar, Edit, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Aggregate, Page,
} from '@syncfusion/ej2-vue-grids'
import { L10n, setCulture } from '@syncfusion/ej2-base'
import {
  BRow, BCol, VBToggle, BCard,
} from 'bootstrap-vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'
import { italian } from '@/@core/grid-translation/it-IT'
import AssociateProductsModalEventHandler from '@/@core/components/logistica/AssociateProductsModalEventHandler.vue'
import { getProdottiNonAssociati } from '@/@core/api-service/catalog/products'

Vue.use(GridPlugin)
Vue.use(ToastPlugin)
setCulture('it-IT')
L10n.load(italian)

export default Vue.extend({
  components: {
    BRow,
    BCol,
    BCard,
    AssociateProductsModalEventHandler,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      idProductLocked: { id: 0 },
      productToAssociate: [],
      // customRulesQuantityOfOperationsRettifica: { requirend: [true, 'Campo Obbligatorio'], range: [[-9999, 9999], 'Inserire valori da -9999 a 9999'] },
      buttonSaveDisabled: true,
      prodottiNonAssociati: [],
      show: false,

      filterOptions: {
        type: 'Menu',
      },
      filter: {
        type: 'CheckBox',
      },
      selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
      editSettings: {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal',
      },
      toolbar: [
        'ColumnChooser', 'Print',
        {
          text: 'Associa Selezionati', tooltipText: 'Associa Selezionati', prefixIcon: 'e-icons e-group-2', id: 'associaProdSelezionati',
        },
      ],
      contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
        'Copy', 'Save', 'Cancel',
        'FirstPage', 'PrevPage',
        'LastPage', 'NextPage', 'Group', 'Ungroup', {
          text: 'Associa', target: '.e-content', id: 'Associa', iconCss: 'e-icons e-group-2',
        }],
      pageSettings: { pageSize: 25, pageSizes: [25, 50, 100, 1000], pageCount: 5 },
      title: 'Prodotti Non Associati',
      optionType: [],
      configFlatPicker: {
        static: false,
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'M-j-Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian, // locale for this instance only
      },
      commands: [
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        {
          type: 'Associa',
          id: 'Associa',
          target: '.e-column',
          buttonOption: { iconCss: 'e-icons e-group-2', cssClass: 'e-flat' },
        },
      ],
    }
  },
  watch: {
  },
  computed: {
    titleComputed() {
      return this.title
    },
  },
  mounted() {
    this.getProdottiApi()
  },
  methods: {
    commandColumm(args) {
      if (args.commandColumn.type === 'Associa') {
        const arrayTemp = []
        arrayTemp.push(args.rowData)
        this.productToAssociate = [...arrayTemp]
        // console.log(this.productToAssociate)
        this.$refs['modal-associate-product-event-handler'].openModal()
      }
    },
    getProdottiApi() {
      getProdottiNonAssociati().then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          this.prodottiNonAssociati = [...res.data.prodottiNonAss]
        }
      })
    },
    dataBound() {
      this.$refs.overviewgridProductNotAssociate.autoFitColumns()
    },
    setTitle(title) {
      this.title = title
    },
    openSidebar() {
      this.show = true
    },
    alertQuantityConfirm(index) {
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'La quantità inserita è minore di quella presente in magazzino, confermi?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (!res.value) {
          this.$refs.overviewgridProductNotAssociate.selectRow(index)
          this.$refs.overviewgridProductNotAssociate.startEdit()
          // se confermo cambiaù
        }
      })
    },
    actionTableBegin() {
    },
    actionTableComplete() {
      // console.log('actionComplete', args)
    },
    removeAssociateFromTable() {
      /* records.forEach(el => {
        this.$refs.overviewgridProductNotAssociate.deleteRecord('id', el.id_prodotto_associato)
      }) */
      this.$refs.toastRef.show({
        title: 'Operazione Completata!', content: 'Prodotto Associato Correttamente', cssClass: 'e-toast-success', icon: 'e-success e-circle-check',
      })
    },
    toolbarClick(args) {
      // console.log(args)
      if (args.item.id === 'associaProdSelezionati' && this.$refs.overviewgridProductNotAssociate.getSelectedRecords().length > 0) {
        this.productToAssociate = this.$refs.overviewgridProductNotAssociate.getSelectedRecords()
        // this.productToAssociate = this.$refs.overviewgridProductNotAssociate.getSelectedRecords().map(el => ({ id: el.id }))
        this.$refs['modal-associate-product-event-handler'].openModal()
      }
      if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: 'prova.pdf',
        }
        this.$refs.overviewgridProductNotAssociate.pdfExport(exportProperties)
        // this.$refs.overviewgridProductNotAssociate.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.overviewgridProductNotAssociate.excelExport()
      }
      if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.overviewgridProductNotAssociate.csvExport()
      }
    },
  },
  provide: {
    grid: [Toolbar, Filter, Selection, Sort, ColumnChooser, Resize, Scroll, Aggregate, Edit, ContextMenu, CommandColumn, Page],
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
<style scoped>
#toast_type {
  z-index: 99999 !important;
}
</style>
