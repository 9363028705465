var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-associate-product-event-handler",attrs:{"id":"modal-associate-product-event-handler","ok-only":"","ok-title":"Crea","centered":"","size":"md","title":_vm.titleEventHandler,"no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.handleOk($event)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{ref:"validation-observer-product-associate-modal-event-handler"},[_c('b-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('ejs-grid',{attrs:{"data-source":_vm.productToAssociateComputed,"allow-paging":true,"page-settings":_vm.pageSettings}},[_c('e-columns',[_c('e-column',{attrs:{"is-primary-key":true,"field":"codice","header-text":"Codice","text-align":"left","clip-mode":"EllipsisWithTooltip","width":"30"}}),_c('e-column',{attrs:{"field":"nome","header-text":"Nome","width":"120","clip-mode":"EllipsisWithTooltip"}})],1)],1)],1),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Prodotto Unificato","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome Prodotto Unificato","label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"filterable":"","reduce":function (valore) { return ({ id: valore.id }); },"options":_vm.allProductsOptions},on:{"open":_vm.getAllProducts},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Ricerca prodotti...")])]}},{key:"loading",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Caricamento prodotti...")])]}},{key:"searching",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Sto cercando...")])]}},{key:"option",fn:function(option){return [_c('div',[_c('span',[_vm._v(_vm._s(option.codice.concat('-', option.nome)))])])]}}],null,true),model:{value:(_vm.prodottoUnificato),callback:function ($$v) {_vm.prodottoUnificato=$$v},expression:"prodottoUnificato"}}),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('ejs-toast',{ref:"toastRef",attrs:{"id":"toast_type","position":{ X: 'Right' }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }